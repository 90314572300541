<template>
  <div class="container">
    <h4 class="text-center my-4">Разница</h4>

    <PrimePreloader v-if="loading"/>

    <div v-else class="my-3">

      <DataTable :value="differenceDisciplines" showGridlines stripedRows responsiveLayout="scroll">
        <Column header="№" :style="{width: '60px'}">
          <template #body="{index}">
            {{index+1}}
          </template>
        </Column>
        <Column field="education_discipline_name" header="Дисциплина"></Column>
        <Column field="credit" header="Кредит"></Column>
        <Column field="semester" header="Семестр"></Column>
        <Column header="Преподаватель">
          <template #body="{data}">
              {{data.pps_data_lastname}} {{data.pps_data_firstname}}
          </template>
        </Column>
        <Column header="Студенты">
          <template #body="{data}">
            <div class="my-2" v-for="(student, studentIndex) in data.students" :key="studentIndex">
              {{studentIndex+1}}. {{student.barcode}} {{student.last_name}} {{student.first_name}}
            </div>
          </template>
        </Column>
        <Column header="Действие">
          <template #body="{data}">
            <div v-if="+data.is_practise===1">Практика</div>
            <div v-else>
              <Button label="Создать" class="p-button-rounded" :disabled="!!data.mdl_course_id"
                      :loading="loadingPostMdl[data.education_discipline_id+data.pps_id]"
                      @click="postMdlCourseDifference(data.education_discipline_id, data.credit, data.semester, data.pps_id)"/>
            </div>
          </template>
        </Column>
      </DataTable>

    </div>

  </div>
</template>

<script>
  import {mapActions, mapState} from "vuex"

  export default {
    name: "DepartmentDifference",
    data() {
      return {
        loading: true,
        loadingPostMdl: {},
      }
    },
    computed: {
      ...mapState('departmentDifference', ['differenceDisciplines'])
    },
    methods: {
      ...mapActions('departmentDifference', ['GET_DIFFERENCE_DISCIPLINES', 'POST_MDL_COURSE_DIFFERENCE']),
      async postMdlCourseDifference(education_discipline_id, credit, semester, pps_id) {
        this.loadingPostMdl[education_discipline_id+pps_id] = true
        const res = await this.POST_MDL_COURSE_DIFFERENCE({education_discipline_id, credit, semester, pps_id})
        if (res) {
          await this.GET_DIFFERENCE_DISCIPLINES()
          this.$message({title: 'Успешно создано'})
        }
        this.loadingPostMdl[education_discipline_id+pps_id] = false
      },
    },
    async mounted() {
      await this.GET_DIFFERENCE_DISCIPLINES()
      this.loading = false
    }
  }
</script>

<style scoped>

</style>
